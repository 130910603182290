dmx.Component('datastore', {

  initialData: {
    batch: false,
    data: [],
  },

  attributes: {
    session: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Object,
      default: null,
    },
  },

  methods: {
    insert (data) {
      this._insert(data);
    },

    update (filter, data) {
      this._update(filter, data);
    },

    upsert (filter, data) {
      this._upsert(filter, data);
    },

    delete (filter) {
      this._delete(filter);
    },

    clear () {
      this._clear();
    },

    get (filter) {
      return this._filter(filter)
    },

    startBatch () {
      this.set('batch', true);
    },

    endBatch () {
      this.set('batch', false);
      this._updateData();
    },
  },

  events: {
    inserted: Event,
    updated: Event,
    deleted: Event,
  },

  render: false,

  init () {
    this._records = [];
    this._lastid = 0;

    this._save = this._save.bind(this);
    this._updateData = this._updateData.bind(this);
    this._storageHandler = this._storageHandler.bind(this);

    window.addEventListener('storage', this._storageHandler);

    this._read();
  },

  destroy () {
    window.removeEventListener('storage', this._storageHandler);
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('columns')) {
      this._updateData();
    }
  },

  _read () {
    try {
      const data = JSON.parse(this._store().getItem('datastore_' + this.name));

      if (data) {
        if (data.records) this._records = data.records;
        if (data.lastid) this._lastid = data.lastid;
        this._updateData();
      }
    } catch (err) {
      console.warn('Error parsing datastore', err);
    }
  },

  _filter (filter) {
    if (typeof filter == 'number') {
      filter = { $id: filter };
    }

    return this._records.filter(record => {
      if (Array.isArray(filter)) {
        for (const i = 0; i < filter.length; i++) {
          for (const prop in filter[i]) {
            if (record[prop] === filter[i][prop]) return true;
          }
        }
      } else {
        for (const prop in filter) {
          if (record[prop] === filter[prop]) return true;
        }
      }

      return false;
    });
  },

  _insert (data) {
    const result = { inserted: [], deleted: [] };

    if (dmx.debug) {
      console.debug('_insert method');
      console.time('_insert' + this.name);
    }
    
    this._array(data).forEach(entry => {
      const record = this._mergeData({ $id: ++this._lastid }, entry);
      this._records.push(record);
      result.inserted.push(dmx.clone(record));
    });

    if (dmx.debug) {
      console.timeEnd('_insert' + this.name);
    }
  this._save();

    this.dispatchEvent('inserted', null, result);
  },

  _update (filter, data) {
    if (!this._validData(data)) {
      console.warn('Invalid data!', data);
      return;
    }

    const result = { inserted: [], deleted: [] };

    if (dmx.debug) {
      console.debug('_update method');
      console.time('_update' + this.name);
    }

    this._filter(filter).forEach(record => {
      const updatedRecord = this._mergeData(record, data);
      if (!dmx.equal(record, updatedRecord)) {
        result.deleted.push(dmx.clone(record));
        result.inserted.push(dmx.clone(updatedRecord));
        Object.assign(record, updatedRecord);
      }
    });

    if (dmx.debug) {
      console.timeEnd('_update' + this.name);
    }

    this._save();

    this.dispatchEvent('updated', null, result);
  },

  _upsert (filter, data) {
    const toUpdate = this._filter(filter);

    if (toUpdate.length) {
      this._update(filter, data);
    } else {
      this._insert(data);
    }
  },

  _delete (filter) {
    const result = { inserted: [], deleted: [] };

    if (typeof filter == 'number') {
      filter = { $id: filter };
    }

    if (dmx.debug) {
      console.debug('_delete method');
      console.time('_delete' + this.name);
    }

    this._records = this._records.filter(record => {
      for (const prop in filter) {
        if (record[prop] === filter[prop]) {
          result.deleted.push(dmx.clone(record));
          return false;
        }
      }

      return true;
    });

    if (dmx.debug) {
      console.timeEnd('_delete' + this.name);
    }

    this._save();

    this.dispatchEvent('deleted', null, result);
  },

  _clear () {
    this._records = [];
    this._lastid = 0;
    this._save();
  },

  _validData (data) {
    return typeof data == 'object' && !Array.isArray(data);
  },

  _mergeData (record, data) {
    if (dmx.debug) {
      console.debug('Merge Data');
      console.time('merge' + this.name);
    }

    const merged = Object.assign({}, record);

    for (const prop in data) {
      let value = data[prop];

      if (this._isExpression(value)) {
        value = dmx.parse(value, dmx.DataScope(record, this));
      }

      merged[prop] = value;
    }

    if (dmx.debug) {
      console.timeEnd('merge' + this.name);
    }

    return merged;
  },

  _updateData () {
    if (this.data.batch) return;
    if (this.props.columns && typeof this.props.columns == 'object') {
      if (dmx.debug) {
        console.debug('Update data columns');
        console.time('update' + this.name);
      }
      this.set('data', this._records.map((record, index) => {
        const updatedRecord = dmx.clone(record);
        const scope = dmx.DataScope({ $value: record, $index: index, $key: index , ...record }, this);

        for (const column in this.props.columns) {
          let value = this.props.columns[column];

          if (this._isExpression(value)) {
            value = dmx.parse(value, scope);
          }

          updatedRecord[column] = value;
        }

        return updatedRecord;
      }));
      if (dmx.debug) {
        console.timeEnd('update' + this.name);
      }
    } else {
      if (dmx.debug) {
        console.debug('Update data records');
        console.time('update' + this.name);
      }
      this.set('data', dmx.clone(this._records));
      if (dmx.debug) {
        console.timeEnd('update' + this.name);
      }
    }
  },

  _save () {
    this._updateData();

    if (this.delay) {
      clearTimeout(this.delay);
    }

    this.delay = setTimeout(() => {
      if (dmx.debug) {
        console.debug('Save data to storage');
        console.time('store' + this.name);
      }
      const data = JSON.stringify({
        records: this._records,
        lastid: this._lastid,
      });

      this._store().setItem('datastore_' + this.name, data);
      if (dmx.debug) {
        console.timeEnd('store' + this.name);
      }
    });
  },

  _isExpression (value) {
    return typeof value == 'string' && value.includes('{{');
  },

  _array (data) {
    return Array.isArray(data) ? data : [data];
  },

  _store () {
    return window[(this.props.session ? 'session' : 'local') + 'Storage'];
  },

  _storageHandler (event) {
    this._read();
  },

});
